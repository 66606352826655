import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Application,
  ApplicationData,
  Country,
  CRMEntity,
  DataProperty,
  DataRecord,
  EntityAppData,
  EntityObject,
  FieldConfigurationsRequest,
  Form,
  GroupUser,
  IRelationRetrieverService,
  PropertyRight,
  PropertyType,
  RelationFieldTable,
  RelationObjectValue,
} from 'processdelight-angular-components';
import { Observable, map, tap } from 'rxjs';
import { AADUser } from '../models/aadUser';
import { RecordDetail } from '../models/record-detail';
import { UserLicenseInfo } from '../models/userlicenseinfo';
import { varlicense$ } from './startup.service';
import { environment } from 'src/environments/environment';
import { IDatacomponentService } from 'processdelight-angular-components/lib/custom-control/services/idatacomponent.service';
import { DateRange } from '@angular/material/datepicker';
import { Entity } from 'processdelight-angular-components/lib/domain/models/entity';

@Injectable({
  providedIn: 'root',
})
export class IshtarCRMService
  implements IRelationRetrieverService, IDatacomponentService
{
  apiBase = `${environment.appIdentifier}/api`;
  webBase = `${location.origin}/web`;
  constructor(private httpClient: HttpClient) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/web/${path}`);
    return url.toString();
  }

  // filterQuery(filters: Filter[]) {
  //   let filterString = '';
  //   if (filters.length > 0) {
  //     if (filters.some((f) => f.columnName === 'Name')) {
  //       filterString =
  //         filterString +
  //         `&internalFilter=('${
  //           filters.find((f) => f.columnName === 'Name')?.value
  //         }' in Name)`;
  //     } else {
  //       filterString =
  //         filterString +
  //         `&dataFilter=${filters
  //           .map((filter) => `('${filter.value}' in ${filter.columnName})`)
  //           .join(' and ')}`;
  //     }
  //   }
  //   return filterString;
  // }
  orderByQuery(internalSort: boolean, column: string, direction: string) {
    if (!column || !direction) return '';
    else
      return (
        (internalSort ? '&internalOrderBy' : '&dataOrderBy') +
        `=${column}&orderByDirection=${direction.toUpperCase()}`
      );
  }
  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.webBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.webBase}/session/keepalive`, {});
  }

  getEntities(
    pageSize: number,
    page: number,
    sortedColumn: string,
    sortDirection: string,
    internalSort: boolean,
    internalFilterString: string,
    dataFilterString: string
  ): Observable<{
    result: EntityObject<CRMEntity>[];
    pagingCookie: string;
    totalRecordCount: number;
  }> {
    return this.httpClient
      .get<{
        result: EntityObject<CRMEntity>[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(
        `${
          this.webBase
        }/CRMEntity?pageSize=${pageSize}&page=${page}${this.orderByQuery(
          internalSort,
          sortedColumn,
          sortDirection
        )}&internalFilter=${internalFilterString}&dataFilter=${dataFilterString}`
      )
      .pipe(
        map(({ result, pagingCookie, totalRecordCount }) => ({
          result: result.map(
            (c) => new EntityObject<CRMEntity>(this.camelcaseKeys(c))
          ),
          pagingCookie,
          totalRecordCount,
        }))
      );
  }

  getEntity(entityId: string) {
    return this.httpClient
      .get<EntityObject<CRMEntity>>(`${this.webBase}/CRMEntity/${entityId} `)
      .pipe(
        map((entity) => new EntityObject<CRMEntity>(this.camelcaseKeys(entity)))
      );
  }
  getFormTemplates() {
    return this.httpClient
      .get<DataRecord[]>(`${this.webBase}/dc/formTemplates`)
      .pipe(
        map((forms) => forms.map((f) => new DataRecord(this.camelcaseKeys(f))))
      );
  }

  removeEntity(id: string) {
    return this.httpClient.delete<string[]>(`${this.webBase}/CRMEntity/${id}`);
  }

  updateEntity(entity: CRMEntity) {
    return this.httpClient
      .patch<CRMEntity[]>(`${this.webBase}/CRMEntity`, [entity], {})
      .pipe(map((entity) => new CRMEntity(this.camelcaseKeys(entity[0]))));
  }

  addEntity(entity: CRMEntity, formId: string, createTeam?: boolean) {
    return this.httpClient
      .post<CRMEntity>(
        `${this.webBase}/CRMEntity?formId=${formId}&createTeam=${
          createTeam ?? false
        }`,
        entity
      )
      .pipe(map((client) => new CRMEntity(this.camelcaseKeys(client))));
  }

  getCountries() {
    return this.httpClient.get<Country[]>(`${this.webBase}/startup/countries`);
  }

  getLanguages() {
    return this.httpClient.get<any[]>(`${this.webBase}/startup/languages`);
  }

  getTranslations() {
    return this.httpClient.get<any>(
      `${this.webBase}/startup/translations?lang=${varlicense$.value?.language}`
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(`${this.webBase}/startup/users`)
      .pipe(map((users) => users.map((g) => new GroupUser(g))));
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.webBase}/startup/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(g))));
  }

  getGraphUsers() {
    return this.httpClient
      .get<AADUser[]>(`${this.webBase}/startup/graphusers`)
      .pipe(map((users) => users.map((g) => new AADUser(g))));
  }

  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  getPropertyTypes() {
    return this.httpClient
      .get<PropertyType[]>(`${this.webBase}/dc/propertyTypes`, {})
      .pipe(
        map((propertyType) =>
          propertyType.map((p) => new PropertyType(this.camelcaseKeys(p)))
        )
      );
  }

  getRelationFieldTables() {
    return this.httpClient
      .get<RelationFieldTable[]>(`${this.webBase}/dc/relationFieldTables`, {})
      .pipe(
        map((forms) =>
          forms.map((f) => new RelationFieldTable(this.camelcaseKeys(f)))
        )
      );
  }

  getApplicationForms() {
    return this.httpClient
      .get<Form[]>(`${this.webBase}/dc/applicationForms`, {})
      .pipe(map((forms) => forms.map((f) => new Form(this.camelcaseKeys(f)))));
  }

  getCurrentApplication() {
    return this.httpClient
      .get<Application[]>(`${this.webBase}/dc/application`, {})
      .pipe(
        map(
          (applcations) =>
            applcations.map((p) => new Application(this.camelcaseKeys(p)))[0]
        )
      );
  }

  findForm(name: string) {
    return this.httpClient
      .get<Form[]>(`${this.webBase}/dc/forms?filter=Name eq '${name}'`)
      .pipe(map((forms) => forms.map((c) => new Form(this.camelcaseKeys(c)))));
  }

  getPropertyRights() {
    return this.httpClient
      .get<PropertyRight[]>(`${this.webBase}/dc/propertyRights`, {})
      .pipe(
        map((forms) =>
          forms.map((f) => new PropertyRight(this.camelcaseKeys(f)))
        )
      );
  }

  addFieldConfigurations(request: FieldConfigurationsRequest) {
    return this.httpClient
      .post<DataRecord>(`${this.webBase}/dc/fieldConfiguration`, request)
      .pipe(map((record) => new DataRecord(this.camelcaseKeys(record))));
  }

  getFieldConfigurations(recordId?: string) {
    return this.httpClient
      .get<DataRecord>(`${this.webBase}/dc/fieldConfiguration/${recordId}`)
      .pipe(map((record) => new DataRecord(this.camelcaseKeys(record))));
  }

  getTemplateFieldsConfigurations(formId: string) {
    return this.httpClient
      .get<DataRecord>(
        `${this.webBase}/dc/templatefieldConfigurations/${formId}`
      )
      .pipe(map((record) => new DataRecord(this.camelcaseKeys(record))));
  }

  getDataProperties() {
    return this.httpClient
      .get<DataProperty[]>(`${this.webBase}/dc/dataProperties`)
      .pipe(
        map((dataProperties) =>
          dataProperties.map((dp) => new DataProperty(this.camelcaseKeys(dp)))
        )
      );
  }

  addApplicationData(applicationData: ApplicationData[]) {
    return this.httpClient
      .post<ApplicationData[]>(
        `${this.webBase}/dc/ApplicationData`,
        applicationData
      )
      .pipe(
        map((applicationData) =>
          applicationData.map((c) => new ApplicationData(this.camelcaseKeys(c)))
        )
      );
  }

  updateApplicationData<T extends Entity>(
    entityId: string,
    applicationData: EntityAppData<T>
  ) {
    return this.httpClient
      .patch<EntityAppData<T>>(
        `${this.webBase}/dc/ApplicationData/${entityId}`,
        applicationData
      )
      .pipe(map((record) => new EntityAppData<T>(this.camelcaseKeys(record))));
  }

  getRecordDetails(
    formId: string | undefined,
    ishtarClientId: string | undefined
  ): Observable<RecordDetail[]> {
    return this.httpClient
      .get<RecordDetail[]>(
        `${this.webBase}/RecordDetails/${formId}/${ishtarClientId}`
      )
      .pipe(
        map((recordDetails) =>
          recordDetails.map((c) => new RecordDetail(this.camelcaseKeys(c)))
        )
      );
  }

  addForm(form: Form): Observable<DataRecord> {
    return this.httpClient
      .post<Form>(`${this.webBase}/dc/forms`, form)
      .pipe(map((form) => new DataRecord(this.camelcaseKeys(form))));
  }

  updateForm(form: Form): Observable<Form> {
    return this.httpClient
      .patch<Form>(`${this.webBase}/dc/forms`, form)
      .pipe(map((form) => new Form(this.camelcaseKeys(form))));
  }

  deleteForm(form: Form): Observable<Form> {
    return this.httpClient
      .delete<Form>(`${this.webBase}/dc/forms`, {
        body: form,
      })
      .pipe(map((form) => new Form(this.camelcaseKeys(form))));
  }

  getPossibleRelations(
    dataPropertyId: string,
    page: number,
    pageSize: number,
    relationFieldTableId?: string,
    filter?: string
  ): Observable<RelationObjectValue[]> {
    return this.httpClient
      .get<RelationObjectValue[]>(
        `${
          this.webBase
        }/dc/possibleRelations/${dataPropertyId}?page=${page}&pageSize=${pageSize}${
          relationFieldTableId
            ? `&relationFieldTableId=${relationFieldTableId}`
            : ''
        }${filter ? `&filter=${filter}` : ''}`
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }
  getTypeRelations(
    relationFieldTableId: string
  ): Observable<RelationObjectValue[]> {
    return this.httpClient
      .get<RelationObjectValue[]>(
        `${this.webBase}/dc/typeRelations/${relationFieldTableId}`
      )
      .pipe(map((objects) => objects.map((o) => new RelationObjectValue(o))));
  }
}
